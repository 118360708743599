import { createComponent, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import DownloadGame from '@/components/DownloadGame';
import GamePlayerToolbar from '@/components/GamePlayerToolbar';
import { FullGameData } from '@/lib/drupal/models/Games';
import GamePlayerTitle from '@/components/GamePlayerTitle';

interface DownloadGamePodProps extends IntrinsicProps, FullGameData {
  h1?: boolean
  productPrice: string
  gameDownloadUrl: string
  gamePurchaseUrl: string
}

export default createComponent<DownloadGamePodProps>('DownloadGamePod', {}, function DownloadGamePod ({ className }, props) {
  return (
    <Pod h1={props.h1}>
      <Slot name='title'>
        <GamePlayerTitle 
          isExclusive={props.isSwuExclusive}
        >
          {props.title}
        </GamePlayerTitle>
      </Slot>
      <Slot name='toolbar'>
        <GamePlayerToolbar
          id={props.uid}
          title={props.title}
          rating={props.rating}
          numRatings={props.numRatings}
          gameMediaUrl={props.thumbnailImg ?? props.thumbnailImgXl ?? props.mobileThumbnail}
        />
      </Slot>
      <DownloadGame
        imgSrc={props.thumbnailImgXl}
        billboardSrc={props.billboardImg}
        title={props.title}
        description={props.shortDescription}
        href={props.href}
        productSku={props.productSku}
        productPrice={props.productPrice}
        gameDownloadUrl={props.gameDownloadUrl}
        gamePurchaseUrl={props.gamePurchaseUrl}
        hasTrial={props.hasTrial} 
        id={props.uid}      
      />
    </Pod>
  );
});
