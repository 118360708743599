import { If, Slot } from 'react-commons';
import { createComponent } from 'react-commons';

import { useAuthStore } from '@/lib/drupal/stores/auth';
import { PodProps } from '@/components/Pod';
import AccordionPod from '@/components/Pod/AccordionPod';

import style from './index.module.scss';

interface AdminPodProps extends PodProps {
}

export default createComponent<AdminPodProps>('AdminPod', { style }, function AdminPod ({ slots, className }, props) {
  const [ authState ] = useAuthStore();

  return (
    <>
      {
        If(authState.ready && authState.user?.isAdminUser, () => (
          <AccordionPod className={className}>
            <Slot name='title'>
              {slots.title}
            </Slot>
            {slots.defaultSlot}
          </AccordionPod>
        )).EndIf()
      }
    </>
  );
});
