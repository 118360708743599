import { createComponent, If, Slot } from 'react-commons';
import Pod, { PodProps } from '@/components/Pod';
import GameCarousel, { GameCarouselProps, GameCarouselSkeleton } from '@/components/GameCarousel';

interface CarouselPodProps extends GameCarouselProps {
  lazyLoad?: PodProps[ 'lazyLoad' ]
  onAfterLazyLoad?: PodProps[ 'onAfterLazyLoad' ]
  hideNumPlays?: boolean
}

export default createComponent<CarouselPodProps>('CarouselPod', {}, function CarouselPod ({ slots }, props) {
  if (props.lazyLoad && !props.onAfterLazyLoad) throw new Error('CarouselPod requires \'onAfterLazyLoad\' when \'lazyLoad\' is set');

  return (
    <Pod 
      gutter='none'
      lazyLoad={props.lazyLoad}
      onAfterLazyLoad={props.onAfterLazyLoad}
    >
      <Slot name='title'>
        {slots.title}
      </Slot>
      {
        If(props.initialGames.length, () => (
          <GameCarousel {...props} />
        ))
          .Else(() => (
            <GameCarouselSkeleton tallImg={props.tallImg} />
          ))
          .EndIf()
      }
      <Slot name='footer'>
        {slots.footer}
      </Slot>
    </Pod>
  );
});
