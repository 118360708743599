import { NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { createPage, Block, Slot, ButtonLink, If, IconFA, Container } from 'react-commons';
import { useEffect, useState } from 'react';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

import AdminFlagsForm from '@/components/forms/AdminFlagsForm';
import DailyArchivePod from '@/components/pods/DailyArchivePod';
import DailyBonusPod from '@/components/pods/DailyBonusPod';
import DailyGamePod from '@/components/pods/DailyGamePod';
import DiscussionPod from '@/components/pods/DiscussionPod';
import DownloadGamePod from '@/components/pods/DownloadGamePod';
import GameDescriptionPod from '@/components/pods/GameDescriptionPod';
import GamePlayerPod from '@/components/pods/GamePlayerPod';
import GenresPod from '@/components/pods/GenresPod';
import LeaderboardPod from '@/components/pods/LeaderboardPod';
import LeaveCommentPod from '@/components/pods/LeaveCommentPod';
import RelatedGamesPod from '@/components/pods/RelatedGamesPod';
import WeeklyArchivePod from '@/components/pods/WeeklyArchivePod';
import AdminPod from '@/components/Pod/AdminPod';
import Meta, { generateGamePageMetaData } from '@/components/Meta';
import MobileUpsellPod from '@/components/MobileUpsellPod';
import PerDevice from '@/components/PerDevice';
import UpsellPod from '@/components/UpsellPod';
import Pod from '@/components/Pod';
import Announcement from '@/components/Announcement';

import { useAuthStore } from '@/lib/drupal/stores/auth';
import GamesModel, { FullGameData, getDailyGameSrc } from '@/lib/drupal/models/Games';
import { dailyFieldsToDate, dateToDailyFields } from '@/lib/hooks/useDailyArchive';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import { useGenreBreadcrumbs } from '@/lib/hooks/useGameBreadcrumbs';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';
import { useSwagMessages } from '@/lib/hooks/useSwagMessages';
import { getZonedDate } from '@/lib/util/zonedTime';
import { recentlyPlayedActions, useRecentlyPlayed } from '@/lib/stores/recentlyPlayed';
import { getFieldsFromDateQuery } from '@/lib/util/gamePlayerTools';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';

import style from './index.module.scss';
import { useMessageApi } from '@/lib/util/messageApi';
import ClassicGamePageLayout, { ClassicGamePageInner, ClassicGamePlayerBlock } from '@/layouts/ClassicGamePageLayout';
import PlaywireUnit from '@/components/playwire/PlaywireUnit';

interface GameLandingPageProps {
  game: FullGameData
  slug: string
}

export default createPage<GameLandingPageProps>('GameLandingPage', { style }, function GameLandingPage ({ className }, props) {
  const { game } = props;
  const [ isPremiumUser ] = useIsPremiumUser();
  const genreBreadcrumbs = useGenreBreadcrumbs(game.genres, game.isDownloadGame);
  const metaProps = generateGamePageMetaData(game, genreBreadcrumbs);

  // Add the game to the recently played list
  const [ authState ] = useAuthStore();
  const [ , dispatchRecentGames ] = useRecentlyPlayed();
  const router = useRouter();
  useEffect(() => {
    if (!authState.ready) return;
    
    const today = getZonedDate();

    const gameDate = getZonedDate(
      dailyFieldsToDate(
        getFieldsFromDateQuery(router.query.date as string)[ 1 ]
      )
    );

    const isOutOfRange = props.game.pageLayout === 'jigsaw'
      ? true
      : (
        (game.archive && game.archive.frequency === 'daily' && (
          // return false if gameDate is today or within the last two weeks
          gameDate.getTime() >= today.getTime() ||
          gameDate.getTime() <= today.setDate(today.getDate() - 14)
        )) || 
        (game.archive && game.archive.frequency === 'weekly' && (
          // return false if gameDate is today or within the last 14 weeks
          gameDate.getTime() >= today.getTime() ||
          gameDate.getTime() <= today.setDate(today.getDate() - 14 * 7)
        ))
      );

    if (
      !authState.user?.isPremiumUser && 
      (
        props.game.isBonusGame || 
        (!!router.query.date && isOutOfRange)
      )
    ) {
      router.replace('/unlimited');
      return;
    }

    if (!authState.user) return;

    dispatchRecentGames(recentlyPlayedActions.addGame({
      userId: authState.user.uid,
      game: props.game
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authState.ready, props.game ]);

  const [ numPlays, setNumPlays ] = useState(props.game.numPlays);
  useEffect(() => {
    (async () => {
      try {
        setNumPlays(await GamesModel.getViewCount(props.game.uid));
      } catch {
        // Ignore
      }
    })();
  }, [ props.game ]);

  const { login } = useAuthMethods();
  useSwagMessages({
    game: props.game,
    gameSlug: props.game.pageLayout === 'jigsaw' 
      ? 'jigsawpuzzles' 
      : router.query.slug as string,
    onShowLoginDialog: () => login(),
  });

  const tomorrow = getZonedDate();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowsFields = dateToDailyFields(tomorrow);
  const tomorrowsSrc = getDailyGameSrc(
    game.href + '?date=[custom:daily-game-year]/[custom:daily-game-month]/[custom:daily-game-day]',
    tomorrowsFields
  );

  useMessageApi({
    game,
    targetWindowRef: null,
    onToggleFullScreen () {
      return false;
    },
    onDisplayAd () {
      return;
    },
    onDisplayShareDialog () {
      return;
    },
    onNavigateToGameLanding () {
      router.push(`${game.href}/archive`);
    }
  });

  return (
    <ClassicGamePageLayout 
      className={className} 
      key={router.asPath}
      pageName={
        game.pageLayout === 'jigsaw'
          ? 'game_daily_jigsaw'
          : (
            game.pageLayout === 'download'
              ? 'game_download_keyword'
              : 'game_online_keyword'
          )
      }
    >
      {/* Meta information */}
      <Meta 
        {...metaProps}
      />
      
      <input type='hidden' value={game.uid} id="gameId" />

      <AdminPod>
        <Slot name='title'>Admin Flags & Links</Slot>
        <AdminFlagsForm 
          gameId={game.uid}
          genreId={game.genres[ 0 ]}
          isDailyGame={game.isDailyGame}
          isDownloadGame={game.isDownloadGame}
        />
      </AdminPod>

      <PerDevice redirectOnMobile>
        <Slot name='desktop'>
          <ClassicGamePlayerBlock>
            {
              If(game.pageLayout === 'jigsaw', () => (
                <ClassicGamePageInner>      
                  <DailyGamePod
                    h1
                    {...game}
                    slug={props.slug}
                    isPremiumUser={isPremiumUser}
                    isChallengeJigsaw={game.isChallengeJigsaw}
                  />
                </ClassicGamePageInner>
              ))
                .ElseIf(game.pageLayout === 'download', () => (
                  <ClassicGamePageInner>      
                    <DownloadGamePod
                      h1
                      {...game}
                      productPrice={game.paidgamePrice}
                      gameDownloadUrl={game.downloadUrl}
                      gamePurchaseUrl={game.paidgameBuyUrl}
                    />
                  </ClassicGamePageInner>
                ))
                .Else(() => (
                  <GamePlayerPod
                    h1
                    {...game}
                    previewImg={'/images/gamePlayerPlaceholder.png'}
                    autoPlay='afterInterstitial'
                  />
                ))
                .EndIf()
            }
          </ClassicGamePlayerBlock>
          <Block>
            <ClassicGamePageInner>
              {
                If(!authState.user?.isPremiumUser, () => (
                  <Block>
                    <PlaywireUnit unit={
                      { type: 'leaderboard_atf', selectorId: 'playwire__leaderboard' }
                    } />
                  </Block>
                )).EndIf()
              }

              {
                If(game.pageLayout !== 'jigsaw' && game.pageLayout !== 'download', () => (
                  <Block>
                    <Announcement icon={faExpand}>
                      Play your favorite Shockwave games in full-screen!* <br/>
                      Click the {<IconFA icon={faExpand} />} full-screen button above the game to play in full-screen mode.<br />
                      <small>
                        *Not all games are full-screen compatible, and we are working to support them.
                      </small>
                    </Announcement>
                  </Block>
                )).EndIf()
              }

              {
                If(game.pageLayout !== 'jigsaw' && game.bonusGameUrl && authState.ready && authState.user?.isPremiumUser, () => (
                  <DailyBonusPod
                    gameName={game.title}
                    bonusGameImgSrc0={game.thumbnailImgXl}
                    bonusGameUrl0={game.bonusGameUrl}
                    bonusGameImgSrc1={game.thumbnailImgXl}
                    bonusGameUrl1={tomorrowsSrc}
                    weeklyStartDate={game.bonusGameFrequency === 'weekly' && game.archive?.startDate}
                  />
                )).EndIf()
              }

              <GameDescriptionPod
                {...game}
                numPlays={numPlays}
              />

              {
                If(game.walkthrough, () => (
                  <Pod slimTitle>
                    <Slot name='title'>
                      <span>Game Walkthrough</span>
                    </Slot>
                    <Slot name='toolbar'>
                      <ButtonLink 
                        small
                        secondary
                        href={`/gamelanding/${props.slug}/walkthrough`}
                      >
                        View Walkthrough
                      </ButtonLink>
                    </Slot>
                  </Pod>
                )).EndIf()
              }

              {
                If(game.archive && game.archive.frequency === 'weekly', () => (
                  <WeeklyArchivePod 
                    game={game}
                    kind={game.isBonusGame ? 'bonus' : 'daily'}
                  >
                    <Slot name='title'>{game.title} Archive</Slot>
                    <Slot name='footer'>
                      <ButtonLink small secondary href={`/gamelanding/${props.slug}/archive`}>View Full Archive</ButtonLink>
                    </Slot>
                  </WeeklyArchivePod>
                )).ElseIf(game.archive && game.archive.frequency === 'daily', () => (
                  <DailyArchivePod 
                    game={game}
                    kind={game.isBonusGame ? 'bonus' : 'daily'}
                  >
                    <Slot name='title'>{game.title} Archive</Slot>
                    <Slot name='footer'>
                      <ButtonLink small secondary href={`/gamelanding/${props.slug}/archive`}>View Full Archive</ButtonLink>
                    </Slot>
                  </DailyArchivePod>
                )).EndIf()
              }

              {
                If(!isPremiumUser, () => (
                  <CaptureClickEvent
                    action='upsell'
                    location='swu_panel_upsell'
                    href='/unlimited'
                    properties={() => ({
                      id: game.uid,
                      title: game.title,
                      view_node: game.href,
                      tag_name: 'upsell_game_swu_promo',
                    })}
                  >
                    <UpsellPod className='upsell_game_swu_promo' />
                  </CaptureClickEvent>
                )).EndIf()
              }

              {
                If(game.genres.length > 0, () => (
                  <RelatedGamesPod
                    gameId={game.uid}
                    gameGenre={game.genres[ 0 ]}
                    isDownloadGame={game.isDownloadGame}
                  />
                )).EndIf()
              }

              <MobileUpsellPod />

              {
                If(game.hasLeaderboards, () => (
                  <LeaderboardPod
                    keyword={game.keyword}
                  />
                )).EndIf()
              }

              <LeaveCommentPod
                gameId={game.uid}
                gamePageLayout={game.pageLayout}
              />

              {
                If(game.hasChatEnabled, () => (
                  <DiscussionPod
                    gameId={game.uid}
                  />
                )).EndIf()
              }

              <GenresPod
                downloadGenres={game.isDownloadGame}
              />
            </ClassicGamePageInner>
          </Block>
        </Slot>

      </PerDevice>

    </ClassicGamePageLayout>
  );
});

export async function getServerSideProps (context: NextPageContext) {
  const slug = context.query.slug as string;

  // Get game data
  let game: FullGameData;
  try {
    game = await GamesModel.getBySlug('gamelanding/' + slug, context.req.headers.cookie);
  } catch (err) {
    return {
      notFound: true,
    };
  }

  // Gather props
  const props: GameLandingPageProps = {
    game,
    slug,
  };

  return {
    props,
  };
}
