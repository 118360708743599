import CarouselPod from '@/components/pods/CarouselPod';
import GamesModel, { GameData } from '@/lib/drupal/models/Games';
import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import downloadGenres from '@/lib/data/downloadGenres.json';
import onlineGenres from '@/lib/data/onlineGenres.json';
import { ButtonLink, Buttons, IconFA, IntrinsicProps, Slot, createComponent } from 'react-commons';

interface RelatedGamesPodProps extends IntrinsicProps {
  gameId: string
  gameGenre: string
  isDownloadGame?: boolean
}

export default createComponent<RelatedGamesPodProps>('RelatedGamesPod', {}, function RelatedGamesPod ({}, props) {
  const [ games, setGames ] = useState<GameData[]>([]);

  const genre = props.isDownloadGame
    ? downloadGenres.find(genre => genre.uid === props.gameGenre)
    : onlineGenres.find(genre => genre.uid === props.gameGenre);
    
  const fetchInitialGames = async (limit: number, offset: number) => {
    setGames(await GamesModel.getRelatedGames(props.gameId, limit, offset));
  };

  const moreGamesUrl = genre?.href || (
    props.isDownloadGame
      ? '/download/all-games'
      : '/online/all-games'
  );
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    setGames([]);

    (async () => {
      setGames(await GamesModel.getRelatedGames(props.gameId, 8, 0));
    })();
  }, [ props.gameId ]);

  return (
    <CarouselPod 
      lazyLoad='whenInViewport'
      onAfterLazyLoad={() => fetchInitialGames(8, 0)}
      initialGames={games}
      fetchMoreGames={async (limit, offset) => (
        await GamesModel.getRelatedGames(props.gameId, limit, offset)
      )}          
      hideNumPlays
    >
      <Slot name='title'>More Games Like This</Slot>
      <Slot name='footer'>
        <Buttons>
          <ButtonLink href={moreGamesUrl} secondary small className='--iconRight'>
            See All Games
            <IconFA icon={faCircleArrowRight} />
          </ButtonLink>
        </Buttons>
      </Slot>
    </CarouselPod>
  );
});
