import style from './index.module.scss';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { IntrinsicProps, createComponent, IconFA, If, useNotifications, For } from 'react-commons';
import { useEffect, useRef, useState } from 'react';
import { useLoadingState } from '@/lib/hooks/useLoadingState';
import { GameTags, labelForGameTag } from '@/lib/drupal/models/Games';
import UsersModel from '@/lib/drupal/models/Users';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import axiosInstance from '@/lib/drupal/api/axiosInstance';

interface RatingsPickerProps extends IntrinsicProps {
  gameId: string
  gameTitle: string
  rating: string
}

export default createComponent<RatingsPickerProps>('RatingsPicker', { style }, function RatingsPicker ({ className }, props) {
  const elRef = useRef(null);

  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const { login } = useAuthMethods();
  const [ authState ] = useAuthStore();
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const [ hasVoted, setHasVoted ] = useState(false);
  const [ selectedRating, setSelectedRating ] = useState<number | null>(null); 
  const [ selectedTags, setSelectedTags ] = useState<string[]>([]);

  const rateGame = async (rating: number) => {
    if (!authState.ready || !authState.user) {
      notify('You must be logged in to rate games.');
      login();
      return;
    }

    setSelectedRating(rating);
  };

  const selectTag = (id: string) => {
    if (selectedTags.includes(id)) {
      setSelectedTags((currentTags) => currentTags.filter((tagId) => tagId !== id));
    } else {
      setSelectedTags((currentTags) => [ ...currentTags, id ]);
    }
  };

  const sendRating = async () => {
    notify(<>Thanks for rating <strong>{props.gameTitle}</strong>!</>);
    setHasVoted(true);

    startLoading();
    try {
      await UsersModel.rateGame(props.gameId, selectedRating);
      if (selectedTags.length) await UsersModel.tagGame(props.gameId, selectedTags);
    } catch {
      notify('There was an error rating this game. Please try again later.', 'error');
      setHasVoted(false);
    }
    finishLoading();
  };

  useEffect(() => {
    if (isLoading || hasVoted) return;
    if (selectedTags.length >= 3) sendRating();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedTags ]);

  useEffect(() => {
    const onClickOutsideHandler = (event) => {
      if (!selectedRating || isLoading || hasVoted) return;

      const outsideEl = event.target.closest('.RatingsPicker');
      if (outsideEl === elRef.current) return;

      sendRating();
    };
    document.addEventListener('mousedown', onClickOutsideHandler);

    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler);
    };
  });

  useEffect(() => {
    if (hasVoted) return;
    if (!authState.ready || !authState.user) return;
    if (!axiosInstance.defaults.headers[ 'X-CSRF-Token' ]) return;

    (async () => {
      startLoading();
      try {
        const hasVoted = await UsersModel.hasRatedGame(props.gameId, authState.user.uid);
        setHasVoted(hasVoted);
      } catch {
        setHasVoted(false);
      }
      finishLoading();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.gameId, authState, hasVoted ]);

  const ratingOptions = Array
    .from(Array(5).keys())
    .map((i) => (i + 1) * 20)
    .reverse();

  const rating = (!parseFloat(props.rating) && selectedRating)
    ? (selectedRating / 20).toFixed(1)
    : parseFloat(props.rating).toFixed(1);

  return (
    <span ref={elRef} className={className}>
      <span className={`Button Button--secondary Button--small ${isLoading && 'Button--loading'}`}>
        {
          If(isLoading || !authState.ready, () => (
            <>&nbsp;&nbsp;&nbsp;</>
          ))
            .ElseIf(hasVoted, () => (
              <span className='RatingsPicker__Rating'>
                <IconFA icon={faStar} /> <span>{rating}</span>
              </span>
            ))
            .Else(() => (
              <span className='RatingsPicker__Picker'>
                {
                  For(ratingOptions, (rating: number, index: number) => (
                    <span 
                      key={index}
                      onClick={() => rateGame(rating)} 
                      className={selectedRating === rating ? '--selected' : ''}
                    >
                      <IconFA icon={faStarOutline} />
                      <IconFA icon={faStar} />
                    </span>
                  ))
                }
              </span>
            ))
            .EndIf()
        }
      </span>
      {
        If(selectedRating !== null && !isLoading && !hasVoted, () => (
          <span className='RatingsPicker__Tags'>
            <p>Pick up to three words to describe this game</p>
            {
              For(GameTags, (key: string) => (
                <span 
                  key={key} 
                  className={selectedTags.includes(key) ? '--selected' : ''}
                  onClick={() => selectTag(key)}
                >
                  {labelForGameTag(key)}
                </span>
              ))
            }
          </span>
        )).EndIf()
      }
    </span>
  );
});
