import PodToolbar from '@/components/PodToolbar';
import SortByButtons from '@/components/SortByButtons';
import { SortCommentsBy, SORT_BY } from '@/lib/hooks/useCommentList';
import { createComponent, Flex, IntrinsicProps } from 'react-commons';

interface CommentListToolbarProps extends IntrinsicProps {
  isLoading: boolean
  sortBy: SortCommentsBy
  setSortBy: (nextSort: string) => void
}

export default createComponent<CommentListToolbarProps>('CommentListToolbar', {}, function CommentListToolbar ({ className }, props) {
  return (
    <PodToolbar isLoading={props.isLoading}>
      <Flex>
        <SortByButtons
          sortMap={SORT_BY}
          sortBy={props.sortBy}
          setSortBy={props.setSortBy}
        />
      </Flex>
    </PodToolbar>
  );
});
