import { Slot } from 'react-commons';
import { createComponent } from 'react-commons';
import { CSSTransition } from 'react-transition-group';

import Pod, { PodProps } from '@/components/Pod';

import style from './index.module.scss';
import HeaderLinkCaret from '@/components/AppHeader/HeaderLinkCaret';
import { useRef, useState } from 'react';
import { useEffectAfterFirstRender } from '@/lib/hooks/useEffectAfterFirstRender';

interface AccordionPodProps extends PodProps {
  open?: boolean
}

export default createComponent<AccordionPodProps>('AccordionPod', { style }, function AccordionPod ({ slots, className }, props) {
  const caretElRef = useRef<HTMLDivElement | null>(null);
  const [ isOpen, setIsOpen ] = useState(props.open);

  const toggleAccordion = () => {
    setIsOpen((currentlyOpen) => !currentlyOpen);
  };

  useEffectAfterFirstRender(() => {
    setIsOpen(props.open);
  }, [ props.open ]);
  
  return (
    <Pod {...props} className={className} >
      <Slot name='title'>
        <span onClick={toggleAccordion}>
          {slots.title}
        </span>
      </Slot>
      <Slot name='toolbar'>
        <span ref={caretElRef} onClick={toggleAccordion}>
          <HeaderLinkCaret className={isOpen && '--open'} />
        </span>
      </Slot>
      <CSSTransition
        in={isOpen}
        timeout={400}
        classNames={'TxFade400'}
        unmountOnExit
      >
        <div>
          {slots.defaultSlot}
        </div>
      </CSSTransition>
      <Slot name='footer'>
        {slots.footer}
      </Slot>
    </Pod>
  );
});
