import { useState, useRef, useEffect } from 'react';
import { clamp, createComponent, Flex, For, IconFA, If, IntrinsicProps } from 'react-commons';
import animate from 'animejs';
import SwGameTile, { SwGameTileSkeleton } from '../SwGameTile';

import style from './index.module.scss';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { GameData } from '@/lib/drupal/models/Games';
import { useLoadingState } from '@/lib/hooks/useLoadingState';
import { useEffectAfterFirstRender } from '@/lib/hooks/useEffectAfterFirstRender';

interface GamePlaceholder {
  uid: string
  title?: boolean
  tallImg?: boolean
  isPlaceholder: true
}

export const CAROUSEL_MIN_INIT_GAMES = 8;
export const CAROUSEL_NUM_GAMES_PER_REQUEST = 4;

function gamesIntoSlides (games: any[]) {
  const slides = [];

  let i = 0;
  const limit = Math.ceil(games.length / 4);
  while (i < limit) {
    slides.push(games.slice(i * 4, i * 4 + 4));
    i++;
  }

  return slides;
};

export interface GameCarouselProps extends IntrinsicProps {
  tallImg?: boolean
  initialGames: GameData[]
  fetchMoreGames?: (limit: number, offset: number) => Promise<GameData[]>
  hideButtons?: boolean
  hideNumPlays?: boolean
}

export default createComponent<GameCarouselProps>('GameCarousel', { style }, function GameCarousel ({ className }, props) {
  if (
    !props.hideButtons && 
    !props.fetchMoreGames
  ) {
    throw new Error('\'fetchMoreGames\' is required when \'hideButtons\' is unset.');
  }

  const initialGames: (GameData | GamePlaceholder)[] = [ ...props.initialGames ] || [];
  
  if (
    initialGames.length > 0 && 
    initialGames.length < CAROUSEL_MIN_INIT_GAMES
  ) {
    let numPlaceholdersRequired = initialGames.length < 4
      ? 4 - initialGames.length
      : CAROUSEL_MIN_INIT_GAMES - initialGames.length;

    while (numPlaceholdersRequired > 0) {
      initialGames.push({
        uid: 'gamePlaceholder__' + numPlaceholdersRequired.toString(),
        tallImg: props.tallImg,
        title: true,
        isPlaceholder: true,
      });
      numPlaceholdersRequired--;
    }
  }

  const [ games, setGames ] = useState<(GameData | GamePlaceholder)[]>(initialGames);
  const [ slides, setSlides ] = useState(gamesIntoSlides(initialGames));
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();

  const elRef = useRef<HTMLDivElement | null>(null);
  const offsetRef = useRef(initialGames.length);
  const currentIndexRef = useRef(0);
  const hasReachedEndRef = useRef(false);
  const totalNumGamesRef = useRef(0);

  const reset = (newGames: GameData[]) => {
    offsetRef.current = newGames.length;
    currentIndexRef.current = 0;
    hasReachedEndRef.current = false;
    totalNumGamesRef.current = 0;

    setGames(newGames);
  };
  
  const fetchNext = async () => {
    if (hasReachedEndRef.current) {
      const fromIndex = offsetRef.current % totalNumGamesRef.current;
      const toIndex = fromIndex + CAROUSEL_NUM_GAMES_PER_REQUEST;

      const moreGames = games.slice(fromIndex, toIndex);
      offsetRef.current += moreGames.length;
      setGames((currentGames) => currentGames.concat(moreGames));

      return;
    }

    const moreGames = await props.fetchMoreGames(CAROUSEL_NUM_GAMES_PER_REQUEST, offsetRef.current);
    offsetRef.current += moreGames.length;
    setGames((currentGames) => currentGames.concat(moreGames));
  };
  
  const scroll = async (direction: 'previous' | 'next') => {
    if (isLoading) return;

    startLoading(true);

    const lastIndex = currentIndexRef.current;

    if (direction === 'next') {
      await fetchNext();

      const maxIndex = Math.ceil(offsetRef.current / 4) - 1;
      currentIndexRef.current = clamp(currentIndexRef.current + 1, 0, maxIndex);
    } else {
      currentIndexRef.current = clamp(currentIndexRef.current - 1, 0, Number.MAX_VALUE);
    }

    const maxIndex = Math.ceil(offsetRef.current / 4) - 1;
    const currentIndex = currentIndexRef.current;

    if (lastIndex !== currentIndex) {
      const width = elRef.current.getBoundingClientRect().width;
      const position = width * currentIndex * -1;

      await animate({
        targets: elRef.current.querySelector('.GameCarousel__Slides'),
        translateX: `${position}px`,
        duration: 500,
        easing: 'easeInOutCubic'
      }).finished;
    }

    else if (lastIndex === currentIndex && maxIndex === currentIndex) {
      hasReachedEndRef.current = true;
      totalNumGamesRef.current = offsetRef.current;

      await scroll('next');
    }

    finishLoading();
  };

  useEffect(() => {
    setSlides(() => gamesIntoSlides(games));
  }, [ games ]);

  useEffectAfterFirstRender(() => {
    reset(props.initialGames);
  }, [ props.initialGames ]);

  return (
    <div ref={elRef} className={className}>
      <div className='GameCarousel__Inner'>
        <div className='GameCarousel__Slides'>
          {
            For(slides, (slide, index: number) => (
              <Flex key={index} justifySpaceAround>
                {
                  For(slide, (game: GameData | GamePlaceholder, k: number) => (
                    <Flex key={`${index}_${k}_${game.uid}`}>
                      {
                        If((game as GamePlaceholder).isPlaceholder, () => (
                          <SwGameTileSkeleton 
                            isHidden
                            {...(game as GamePlaceholder)}
                            tallImg={props.tallImg}
                          />
                        ))
                          .Else(() => (
                            <SwGameTile 
                              {...(game as GameData)}
                              tallImg={props.tallImg}
                              hideNumPlays={props.hideNumPlays}
                            />
                          )).EndIf()
                      }
                    </Flex>
                  ))
                }
              </Flex>
            ))
          }
        </div>
      </div>
      {
        If(props.hideButtons || props.initialGames.length <= 4, () => (<></>))
          .Else(() => (
            <>
              <a className='RouterLink' onClick={() => scroll('previous')}><IconFA icon={faArrowCircleLeft} /></a>
              <a className='RouterLink' onClick={() => scroll('next')}><IconFA icon={faArrowCircleRight} /></a>
            </>
          )).EndIf()
      }
    </div>
  );
});

export interface GameCarouselSkeletonProps extends IntrinsicProps {
  tallImg?: boolean
}

export const GameCarouselSkeleton = createComponent<GameCarouselSkeletonProps>('GameCarousel', { style }, function GameCarouselSkeleton ({ className }, props) {
  return (
    <div className={className + ' GameCarousel--skeleton'}>
      <div className='GameCarousel__Inner'>
        <div className='GameCarousel__Slides'>
          <Flex justifySpaceAround>
            {
              For([ 0, 1, 2, 3 ], (index) => (
                <Flex key={index}>
                  <SwGameTileSkeleton
                    title
                    tallImg={props.tallImg}
                  />
                </Flex>
              ))
            }
          </Flex>
        </div>
      </div>
    </div>
  );
});
