import { Button, Buttons, createComponent, For, IntrinsicProps } from 'react-commons';

interface SortByButtonsProps extends IntrinsicProps {
  sortMap: { [key: string]: string }
  sortBy: string
  setSortBy?: (nextSort: string) => void
  dispatchAction?: any
  dispatchSortBy?: (action: any, payload: any) => void
}

export default createComponent<SortByButtonsProps>('SortByButtons', {}, function SortByButtons ({}, props) {
  const onClick = (nextSort: string) => {
    if (props.setSortBy) props.setSortBy(nextSort);
    else if(props.dispatchSortBy) props.dispatchSortBy(props.dispatchAction, nextSort);
  };

  return (
    <Buttons addons>
      {
        For(props.sortMap, (label: string, key: string) => (
          <Button 
            key={key}
            small 
            primary={props.sortBy === key}
            onClick={() => onClick(key)}
          >
            {label}
          </Button>
        ))
      }
    </Buttons>
  );
});
