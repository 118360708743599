import DropdownLabel from '@/components/DropdownLabel';
import PodToolbar from '@/components/PodToolbar';
import SortByButtons from '@/components/SortByButtons';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { LeaderboardToolbarState, LeaderboardToolbarStateActionType, UpdateToolbarStateFn } from '@/lib/hooks/useLeaderboards';
import { LEADERBOARD_PERIOD } from '@/lib/swag/models/Swag';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Buttons, createComponent, Dropdown, DropdownItem, Flex, For, IconFA, If, IntrinsicProps, Slot } from 'react-commons';
import style from './index.module.scss';

interface LeaderboardToolbarProps extends IntrinsicProps {
  isLoading: boolean
  error?: Error
  toolbarState: LeaderboardToolbarState
  updateToolbarState: UpdateToolbarStateFn
}

export default createComponent<LeaderboardToolbarProps>('LeaderboardToolbar', { style }, function LeaderboardToolbar ({ className }, props) {
  const [ authState ] = useAuthStore();

  const {
    gameModes,
    gameModeLabel,
    currentGameMode,
    currentPeriod,
    showUserScores,
  } = props.toolbarState;

  return (
    <PodToolbar 
      isLoading={props.isLoading || !!props.error}
      shouldShowLoader={() => props.isLoading && !!currentGameMode}
      className={className}
    >
      <Flex>
        <DropdownLabel>
          {gameModeLabel}:
        </DropdownLabel>
        <Dropdown>
          <Slot name='trigger'>
            <Button small>
              {
                If(!currentGameMode, () => (
                  <>---</>
                ))
                  .Else(() => (
                    <>{gameModes.find((gameMode) => gameMode.key === currentGameMode).label}</>
                  ))
                  .EndIf()
              }
              <IconFA icon={faCaretDown} small className='--marSm2__l' />
            </Button>
          </Slot>
          <Slot name='content'>
            {
              For(gameModes, (gameMode, index) => (
                <DropdownItem 
                  key={gameMode.key + '_' + index}
                  active={gameMode.key === currentGameMode}
                  onClick={() => props.updateToolbarState(LeaderboardToolbarStateActionType.SET_CURRENT_GAME_MODE, gameMode.key)}
                >
                  {gameMode.label}
                </DropdownItem>
              ))
            }
          </Slot>
        </Dropdown>
      </Flex>
      <Flex>
        <SortByButtons
          sortMap={LEADERBOARD_PERIOD}
          sortBy={currentPeriod}
          dispatchAction={LeaderboardToolbarStateActionType.SET_CURRENT_PERIOD}
          dispatchSortBy={props.updateToolbarState}
        />
      </Flex>
      {
        If(authState.ready && authState.user, () => (
          <Flex>
            <Buttons>
              <Button 
                small 
                primary={showUserScores}
                onClick={() => props.updateToolbarState(LeaderboardToolbarStateActionType.SET_SHOW_USER_SCORES, !showUserScores)}
              >
                My Scores
              </Button>
            </Buttons>
          </Flex>
        )).EndIf()
      }
    </PodToolbar>
  );
});
