import { useLoadingState } from '@/lib/hooks/useLoadingState';
import { focusInput } from '@/lib/util/focusInput';
import { useEffect, useRef, useState } from 'react';
import { Block, Button, EventListener, FieldType, Flex, Form, If, Slot, TextareaField } from 'react-commons';
import { useForm } from 'react-commons';
import { useNotifications } from 'react-commons';
import { IntrinsicProps, createComponent } from 'react-commons';

import style from './index.module.scss';
import CommentModel from '@/lib/drupal/models/Comments';
import { GamePageLayout } from '@/lib/drupal/models/Games';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';

interface LeaveCommentFormProps extends IntrinsicProps {
  gameId: string
  gamePageLayout: GamePageLayout
  onSuccess?: (data: LeaveCommentFormData) => void
}

interface LeaveCommentFormData {
  comment: string
}

export const leaveCommentFormEvents = new EventListener<{
  showForm: () => void
}>();

export default createComponent<LeaveCommentFormProps>('LeaveCommentForm', { style }, function LeaveCommentForm ({ className }, props) {
  const elRef = useRef<HTMLDivElement>(null);
  const [ authState ] = useAuthStore();
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const { login } = useAuthMethods();

  const form = useForm({
    comment: {
      value: '',
      schema: {
        type: FieldType.ANY,
        required: true,
      }
    }
  });
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });

  const submit = async () => {
    if (!authState.ready || !authState.user) return;
    
    const { data, hasErrors } = form.validate<LeaveCommentFormData>();
    if (hasErrors) return;
    
    startLoading();

    try {
      await CommentModel.leaveComment(props.gameId, props.gamePageLayout, data.comment);
      form.resetForm();
    } catch {
      notify('There was an error submitting your comment. Please try again later.', 'error');
      finishLoading();
      return;
    }

    notify('Thanks for commenting!');
    finishLoading();
  };

  useEffect(() => {
    const handleShowForm = () => {
      const el = elRef.current;
      const brect = el.getBoundingClientRect();

      const top = brect.top + window.scrollY;
      window.scrollTo({ top, behavior: 'smooth' });

      const textarea = elRef.current?.querySelector('textarea');
      focusInput(textarea, true);
    };
    leaveCommentFormEvents.on('showForm', handleShowForm);

    return () => {
      leaveCommentFormEvents.off('showForm', handleShowForm);
    };
  }, []);

  return (
    <div className={className} ref={elRef}>
      <Form onSubmit={submit}>
        <Block>
          <p>
            Having technical issues with a game?&nbsp;
            <a href='https://shockwavehelp.zendesk.com/hc/en-us/requests/new' target='_blank' rel='noreferrer' className='RouterLink'>Contact our support team.</a>
          </p>
        </Block>
        <Block>
          <TextareaField 
            name='comment' 
            form={form} 
            className='Input--medium Input--outline'
            disabled={isLoading || !authState.ready || !authState.user}
          >
            <Slot name='label'>Write Your Comment</Slot>
          </TextareaField>
        </Block>
        <Flex>
          <Flex pullRight>
            {
              If(authState.ready && authState.user, () => (
                <Button 
                  secondary submit className='Button--medium' 
                  loading={isLoading}>
                    Submit Comment
                </Button>
              )).Else(() => (
                <p>
                  Please&nbsp;
                  <strong 
                    className='RouterLink'
                    onClick={login}
                  >
                    Log In
                  </strong>
                  &nbsp;to comment.
                </p>
              )).EndIf()
            }
          </Flex>
        </Flex>
      </Form>
    </div>
  );
});
