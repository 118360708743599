import Link from 'next/link';
import Image from 'next/image';
import { Button, Flex } from 'react-commons';
import { createComponent } from 'react-commons';

import style from './index.module.scss';

export default createComponent('UpsellBanner', { style }, function UpsellBanner ({ className }) {
  return (
    <Link href='/unlimited'>
      <a className={`Box ${className}`}>
        <Flex gap0 alignCenter>
          <Flex directionColumn className='UpsellBanner__Img'>
            <Image
              src='/images/shockwaveUnlimited.svg'
              alt='Shockwave.com'
              width={207}
              height={72}
            />
          </Flex>
          <p>
            Shockwave UNLIMITED members can play this game with no time limits at no additional cost!
          </p>
          <Button secondary small>Subscribe</Button>
        </Flex>
      </a>
    </Link>
  );
});
