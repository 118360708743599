import { createComponent, Flex, IntrinsicProps } from 'react-commons';
import style from './index.module.scss';

interface DropdownLabelProps extends IntrinsicProps {
}

export default createComponent<DropdownLabelProps>('DropdownLabel', { style }, function DropdownLabel ({ className }, props) {
  return (
    <Flex alignCenter tag='label' className={'DropdownLabel --padSm2__r'}>
      <strong>{props.children}</strong>
    </Flex>
  );
});
