import { createComponent, IntrinsicProps, IconFA } from 'react-commons';

import style from './index.module.scss';
import { faCircleExclamation, faTriangleCircleSquare, IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface AnnouncementProps extends IntrinsicProps {
  error?: Error
  onTryAgain?: () => void
  icon?: IconDefinition
}

export default createComponent<AnnouncementProps>('Announcement', { style }, function Announcement ({ className }, props) {
  return (
    <div className={className + ' Box'}>
      <IconFA icon={props.icon || faCircleExclamation} />
      <p>{props.children}</p>
    </div>
  );
});
