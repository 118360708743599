import { Block, ButtonLink, Buttons, Content, createComponent, Flex, For, If, IntrinsicProps, RouterLink, Title } from 'react-commons';

import style from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { useGenreBreadcrumbs } from '@/lib/hooks/useGameBreadcrumbs';
import GamesModel from '@/lib/drupal/models/Games';

interface GameDescriptionProps extends IntrinsicProps {
  gameId: string
  description: string
  instructions: string
  requirements: string
  credits: string
  genreIds: string[]
  numPlays: number
  isDownloadGame?: boolean
}

function hasText (text: string) {
  text = text.trim().toLowerCase();
  return !!text && text !== 'null' && text !== '<p>null</p>';
}

export default createComponent<GameDescriptionProps>('GameDescription', { style }, function GameDescription ({ className }, props) {
  const breadcrumbs = useGenreBreadcrumbs(
    props.genreIds, 
    props.isDownloadGame,
    true
  );

  const [ topTags, setTopTags ] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const tags = await GamesModel.getTopTags(props.gameId);
      setTopTags(tags);
    })();
  }, [ props.gameId ]);

  return (
    <Flex container gap0 className={className}>
      <Flex twoThirds directionColumn>
        {
          If(hasText(props.description), () => (
            <Block>
              <Content>
                <Title size6 h6>Description</Title>
                <div dangerouslySetInnerHTML={{ __html: props.description }} />
              </Content>
            </Block>
          )).EndIf()
        }
        {
          If(hasText(props.instructions), () => (
            <Block>
              <Content>
                <Title size6 h6>Instructions</Title>
                <div dangerouslySetInnerHTML={{ __html: props.instructions }} />
              </Content>  
            </Block>
          )).EndIf()
        }
        {
          If(breadcrumbs.length, () => (
            <Block>
              <Content>
                <Title size6 h6>Categories</Title>
                <Buttons>
                  {
                    For(breadcrumbs, (item, i) => (
                      <ButtonLink key={i} href={item.href} small secondary>
                        {item.text}
                      </ButtonLink>
                    ))
                  }
                </Buttons>
              </Content>
            </Block>
          )).EndIf()
        }
        {
          If(props.numPlays, () => (
            <Block>
              <Content>
                <Title size6 h6>All-Time Plays</Title>
                <div>{props.numPlays.toLocaleString()}</div>
              </Content>
            </Block>
          )).EndIf()
        }
      </Flex>
      <Flex directionColumn>
        {
          If(props.isDownloadGame && hasText(props.requirements), () => (
            <Block>
              <Content>
                <Title size6 h6>Minimum Requirements</Title>
                <div dangerouslySetInnerHTML={{ __html: props.requirements }} />
              </Content>
            </Block>
          )).EndIf()
        }
        {
          If(topTags.length, () => (
            <Block>
              <Content>
                <Title size6 h6>Top Tags</Title>
                <p>
                  {topTags.join(', ')}
                </p>
              </Content>
            </Block>
          )).EndIf()
        }
        {
          If(hasText(props.credits), () => (
            <Block>
              <Content>
                <Title size6 h6>Credits</Title>
                <div dangerouslySetInnerHTML={{ __html: props.credits }} />
              </Content>
            </Block>
          )).EndIf()
        }
      </Flex>
    </Flex>
  );
});
