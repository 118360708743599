import { useLoadingState } from '@/lib/hooks/useLoadingState';
import { Block, Button, CheckboxField, Content, FieldType, Form, If, IntrinsicProps, Slot, Title, createComponent, useForm, useNotifications } from 'react-commons';

import style from './index.module.scss';
import GamesModel, { AdminFlags } from '@/lib/drupal/models/Games';
import { useState, useEffect, useRef } from 'react';
import onlineGenres from '@/lib/data/onlineGenres.json';
import downloadGenres from '@/lib/data/downloadGenres.json';

const flagKeys = [
  'basic_node_test_flag',
  'coming_soon_flag',
  'daily_carousel',
  'daily_game_featured',
  'download_carousel',
  'download_featured',
  'download_top_games',
  'exclusive_partner',
  'front_page_carousel',
  'front_page_featured',
  'front_top_games',
  'nn_cent_sale',
  'online_carousel',
  'online_featured',
  'online_top_games',
  'random_game_assignment',
  'selected_feature_legacy_',
  'shockwave_self_extra_sidebar_pro',
  'taxonomy_carousel',
  'taxonomy_featured',
  'taxonomy_top_games',
  'unpublish_games'
];

const schema = flagKeys.reduce((acc, key) => {
  acc[ key ] = {
    value: false,
    schema: {
      type: FieldType.BOOLEAN,
      trueValue: 1,
      falseValue: 0,
    }
  };
  return acc;
}, {});

interface AdminFlagsFormProps extends IntrinsicProps {
  gameId: string
  genreId: string
  isDailyGame: boolean
  isDownloadGame: boolean
}

export default createComponent<AdminFlagsFormProps>('AdminFlagsForm', { style }, function AdminFlagsForm ({ className }, props) {
  const form = useForm(schema);
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const lastFlagsRef = useRef<AdminFlags | null>(null);

  useEffect(() => {
    (async () => {
      const results = await GamesModel.getAdminFlags(props.gameId);
      lastFlagsRef.current = results;
      form.setData(results);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.gameId ]);

  const onSubmit = async () => {
    startLoading();

    const lastFlags = lastFlagsRef.current;
    const { data: currentFlags } = form.validate<AdminFlags>();

    const changedFlags = Object.keys(currentFlags).reduce((acc, key) => {
      if (currentFlags[ key ] !== lastFlags[ key ]) {
        acc[ key ] = currentFlags[ key ];
      }
      return acc;
    }, {} as AdminFlags);

    if (Object.keys(changedFlags).length === 0) {
      notify('No changes were made.', 'warning');
      finishLoading();
      return;
    }

    try {
      await Promise.all(
        Object.keys(changedFlags)
          .map((key: keyof AdminFlags) => GamesModel.setAdminFlag(props.gameId, key, changedFlags[ key ]))
      );
      notify(`${Object.keys(changedFlags).length} flag(s) updated.`, 'success');
    } catch {
      notify('There was an error saving the game flags.', 'error');
    }

    finishLoading();
  };

  const genre = props.genreId !== undefined
    ? (
      props.isDownloadGame
        ? downloadGenres.find(g => g.uid === props.genreId)
        : onlineGenres.find(g => g.uid === props.genreId)
    )
    : undefined;
  
  return (
    <Form className={className} onSubmit={onSubmit}>
      <Block>
        <Title h6 size6>Admin Game Flags</Title>

        <CheckboxField name='front_page_carousel' form={form}>
          <Slot name='description'>
            <strong>Front Page</strong> carousel (positions 1 and 2)
          </Slot>
        </CheckboxField>

        <CheckboxField name='selected_feature_legacy_' form={form}>
          <Slot name='description'>
            <strong>Front Page</strong> carousel (position 3)
          </Slot>
        </CheckboxField>

        {
          If(genre, () => (
            <>
              <CheckboxField name='taxonomy_carousel' form={form}>
                <Slot name='description'>
                  <strong>Taxonomy Page ({genre.name})</strong> carousel (positions 1 and 2)
                </Slot>
              </CheckboxField>

              <CheckboxField name='taxonomy_featured' form={form}>
                <Slot name='description'>
                  <strong>Taxonomy Page ({genre.name})</strong> carousel (position 3)
                </Slot>
              </CheckboxField>

              <CheckboxField name='taxonomy_top_games' form={form}>
                <Slot name='description'>
                  <strong>Taxonomy Page ({genre.name})</strong> top games
                </Slot>
              </CheckboxField>
            </>
          )).EndIf()
        }

        {
          If(props.isDailyGame, () => (
            <>
              <CheckboxField name='download_carousel' form={form}>
                <Slot name='description'>
                  <strong>Daily</strong> games carousel
                </Slot>
              </CheckboxField>

              <CheckboxField name='download_featured' form={form}>
                <Slot name='description'>
                  <strong>Daily</strong> games featured (top right)
                </Slot>
              </CheckboxField>
            </>
          )).EndIf()
        }

        {
          If(props.isDownloadGame, () => (
            <>
              <CheckboxField name='download_carousel' form={form}>
                <Slot name='description'>
                  <strong>Download</strong> games carousel
                </Slot>
              </CheckboxField>

              <CheckboxField name='download_featured' form={form}>
                <Slot name='description'>
                  <strong>Download</strong> games featured
                </Slot>
              </CheckboxField>

              <CheckboxField name='coming_soon_flag' form={form}>
                <Slot name='description'>
                  <strong>'Coming Soon'</strong> box on download new releases
                </Slot>
              </CheckboxField>
            </>
          )).Else(() => (
            <>
              <CheckboxField name='online_carousel' form={form}>
                <Slot name='description'>
                  <strong>Online</strong> games carousel
                </Slot>
              </CheckboxField>

              <CheckboxField name='online_featured' form={form}>
                <Slot name='description'>
                  <strong>Online</strong> games featured
                </Slot>
              </CheckboxField>
            </>
          )).EndIf()
        }

        <Button submit disabled={isLoading} secondary>Save Changes</Button>
      </Block>
      <br />

      <Block>
        <Title h6 size6>Portal Update Links</Title>

        <p>Currently disabled.</p>
      </Block>
      <br />

      <Block>
        <Title h6 size6>Other Links</Title>

        <Content>
          <p><a href='https://www.shockwave.com/admin/config/development/performance' className='RouterLink'>Cache Clearing Page</a></p>
        </Content>
      </Block>
    </Form>
  );
});
