import { Button, createComponent, IconFA, If, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import SwGamePlayer from '@/components/SwGamePlayer';
import GamePlayerToolbar from '@/components/GamePlayerToolbar';
import GamePlayerTitle from '@/components/GamePlayerTitle';
import GamesModel, { ExtendedGameData, FullGameData } from '@/lib/drupal/models/Games';
import GenreBreadcrumbs from '@/components/GenreBreadcrumbs';
import DiscontinuedGame from '@/components/DiscontinuedGame';
import { useState, useEffect, useRef } from 'react';
import { faExpand, faExpandAlt } from '@fortawesome/free-solid-svg-icons';

interface GamePlayerPodProps extends IntrinsicProps, FullGameData {
  h1?: boolean
  previewImg: string
  autoPlay?: boolean | 'afterInterstitial',
}

export default createComponent<GamePlayerPodProps>('GamePlayerPod', {}, function GamePlayerPod ({ className }, props) {
  const style: { [key: string]: string | number } = {
    width: 'auto',
  };

  if (props.embed.width && !props.displayAlternateSuggestion) {
    const padding = 40; // 1rem on each side
    style.width = props.embed.width + padding;
  }

  const [ alternateGame, setAlternateGame ] = useState<ExtendedGameData>(null);

  const fetchAlternateGame = async (url: string) => {
    const alternateGame = await GamesModel.getRandomGame(url);
    setAlternateGame(alternateGame);
  };

  const currentGameRef = useRef<string | null>(null);

  useEffect(() => {
    if (props.id === currentGameRef.current) {
      return;
    }

    currentGameRef.current = props.id;

    if (props.displayAlternateSuggestion) {
      if (props.alternateSuggestionGame) {
        setAlternateGame(props.alternateSuggestionGame);
      } else {
        fetchAlternateGame(props.randomGameSuggestionUrl);
      }
    } else {
      setAlternateGame(null);
    }
  }, [ props ]);

  const [ isFullScreen, setFullScreen ] = useState(false);

  const toggleFullScreen = () => {
    setFullScreen((fullScreen) => !fullScreen);
  };
  
  return (
    <Pod gutter='none' h1={props.h1} style={style} className={className}>
      <Slot name='title'>
        <GamePlayerTitle 
          isExclusive={props.isSwuExclusive}
        >
          {props.title}
        </GamePlayerTitle>
      </Slot>
      <Slot name='subtitle'>
        {
          If(props.genres.length, () => (
            <GenreBreadcrumbs
              genreIds={props.genres}
              isDownloadGame={props.isDownloadGame}
            />
          )).EndIf()
        }
      </Slot>
      <Slot name='toolbar'>
        <Button 
          small 
          primary 
          onClick={toggleFullScreen}
          aria-label='Toggle Fullscreen'
        >
          <IconFA icon={faExpand} />
        </Button>
        <GamePlayerToolbar
          id={props.uid}
          title={props.title}
          rating={props.rating}
          numRatings={props.numRatings}
          gameMediaUrl={props.thumbnailImg ?? props.thumbnailImgXl ?? props.mobileThumbnail}
        />
      </Slot>
      
      {
        If(props.displayAlternateSuggestion, () => (
          <>
            {
              If(alternateGame, () => (
                <DiscontinuedGame 
                  game={alternateGame}
                />
              )).Else(() => (
                <></>
              )).EndIf()
            }
          </>
        )).Else(() => (
          <SwGamePlayer 
            src={props.embed.src} 
            previewImg={props.previewImg}
            autoPlay={props.autoPlay}
            width={props.embed.width}
            height={props.embed.height}
            isFlash={props.embed.isFlash}
            fullScreen={isFullScreen}
            onClickExitFullScreen={() => setFullScreen(false)}
          />
        )).EndIf()
      }
    </Pod>
  );
});
