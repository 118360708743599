import { Block, Button, ButtonLink, Flex, For, If, IntrinsicProps } from 'react-commons';
import { createComponent } from 'react-commons';
import Image from 'next/image';

import style from './index.module.scss';
import GameTileImg from '@/components/GameTileImg';
import UpsellBanner from '../UpsellBanner';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';
import GamepadIcon from '@/components/GamepadIcon';
import { useDetectOperatingSystem } from '@/lib/hooks/useDetectOs';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';

interface DownloadGameProps extends IntrinsicProps {
  imgSrc: string
  billboardSrc: string[]
  title: string
  description: string
  href: string
  productSku: string
  productPrice: string
  gameDownloadUrl: string
  gamePurchaseUrl: string
  hasTrial: boolean
  id: string
}

export default createComponent<DownloadGameProps>('DownloadGame', { style }, function DownloadGame ({ className }, props) {
  const [ isPremiumUser ] = useIsPremiumUser();
  const os = useDetectOperatingSystem();

  return (
    <div className={className}>
      <Block>
        <Flex container gap0>

          {/* Big thumbnail */}
          <Flex>
            <GameTileImg 
              extraLarge
              src={props.imgSrc} 
              title={props.title}              
            />
          </Flex>

          <Flex fit>
            <div className='--wide'>
              <Flex wide tall directionColumn>

                {/* Description */}
                <p className='DownloadGame__Description --marSm2__b'>
                  {props.description}
                </p>

                {/* Buttons */}
                <Flex wide>
                  <div className='--wide'>
                    <Flex container className='Flex--gapButtons'>
                      {
                        If(isPremiumUser || props.hasTrial, () => (
                          <Flex>
                            {
                              If(os !== 'Windows', () => (
                                <Button
                                  small 
                                  fullWidth
                                  secondary
                                  disabled
                                >
                                  Download (Windows Only)
                                </Button>
                              )).ElseIf(isPremiumUser, () => (
                                <CaptureClickEvent
                                  action='purchase_entry'
                                  className='basketadd_download_buynow0'
                                  location='game_panel'
                                  href={props.gameDownloadUrl}
                                  properties={() => ({
                                    tag_name: 'basketadd_download_buynow',
                                    id: props.id,
                                    title: props.title,
                                    view_node: props.href,
                                  })}
                                >
                                  <ButtonLink 
                                    small 
                                    secondary 
                                    fullWidth
                                    href={props.gameDownloadUrl}
                                    className='basketadd_download_buynow0'
                                  >
                                    Download Now
                                  </ButtonLink>
                                </CaptureClickEvent>
                              )).ElseIf(props.hasTrial, () => (
                                <CaptureClickEvent
                                  action='upsell'
                                  location='game_panel'
                                  href={props.gameDownloadUrl}
                                  properties={() => ({
                                    tag_name: 'upsell_download_trydemo',
                                    id: props.id,
                                    title: props.title,
                                    view_node: props.href,
                                  })}
                                >
                                  <ButtonLink 
                                    small 
                                    secondary 
                                    fullWidth
                                    href={props.gameDownloadUrl}
                                    className='upsell_download_trydemo'
                                  >
                                    Download Free Trial
                                    <GamepadIcon />
                                  </ButtonLink>
                                </CaptureClickEvent>
                              )).EndIf()
                            }
                          </Flex>
                        )).EndIf()
                      }
                      <Flex>
                        <CaptureClickEvent
                          action='purchase_entry'
                          className='basketadd_download_buynow1'
                          location='game_panel'
                          href={props.gamePurchaseUrl}
                          properties={() => ({
                            tag_name: 'basketadd_download_buynow',
                            id: props.id,
                            title: props.title,
                            view_node: props.href,
                          })}
                        >
                          <ButtonLink 
                            small 
                            primary 
                            fullWidth
                            href={props.gamePurchaseUrl}
                            className='basketadd_download_buynow1'
                          >
                            Buy Now For ${props.productPrice}
                          </ButtonLink>
                        </CaptureClickEvent>
                      </Flex>
                    </Flex>
                  </div>
                </Flex>

                {/* Preview images */}
                <Flex wide pullBottom>
                  <div className='--wide'>
                    <Flex className='DownloadGame__Billboards' container gap0>
                      {
                        For(props.billboardSrc, (src, i: number) => (
                          <Flex fit key={i}>
                            <Image
                              src={src}
                              alt={`${props.title} preview ${i + 1}`}
                              width={288}
                              height={177}
                              draggable={false}
                            />
                          </Flex>
                        ))
                      }
                    </Flex>
                  </div>
                </Flex>

              </Flex>
            </div>
          </Flex>

        </Flex>
      </Block>

      {
        If(!isPremiumUser, () => (
          <Block>
            <CaptureClickEvent
              action='upsell'
              location='game_header_banner'
              href='/unlimited'
              properties={() => ({
                tag_name: 'upsell_download_swu_promo',
                id: props.id,
                title: props.title,
                view_node: props.href,
              })}
            >
              <UpsellBanner className='upsell_download_swu_promo' />
            </CaptureClickEvent>
          </Block>
        )).EndIf()
      }
    </div>
  );
});
