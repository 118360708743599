import { createComponent, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import LeaveCommentForm from '@/components/forms/LeaveCommentForm';
import { GamePageLayout } from '@/lib/drupal/models/Games';

interface LeaveCommentPodProps extends IntrinsicProps {
  gameId: string
  gamePageLayout: GamePageLayout
}

export default createComponent<LeaveCommentPodProps>('LeaveCommentPod', {}, function LeaveCommentPod ({}, props) {
  return (
    <Pod>
      <Slot name='title'>Leave a Comment</Slot>
      <LeaveCommentForm 
        gameId={props.gameId}
        gamePageLayout={props.gamePageLayout}
      />
    </Pod>
  );
});
