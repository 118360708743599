import style from './index.module.scss';
import { IntrinsicProps, createComponent } from 'react-commons';

interface StatsLabelProps extends IntrinsicProps {
  value: number | string
}

export default createComponent<StatsLabelProps>('StatsLabel', { style }, function StatsLabel ({ className }, props) {
  let value = props.value;
  if (typeof props.value === 'number') {
    value = props.value.toLocaleString();
  }
  return (
    <small className={className}>
      <strong>{value}</strong>&nbsp;{props.children}
    </small>
  );
});
