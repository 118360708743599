import { useLoadingState } from '@/lib/hooks/useLoadingState';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Button, Buttons, Flex, IconFA, If, IntrinsicProps, Loader, storage, useNotifications } from 'react-commons';
import { createComponent } from 'react-commons';
import UserAvatar from '../UserAvatar';

import style from './index.module.scss';
import UsersModel from '@/lib/drupal/models/Users';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import Link from 'next/link';
import { getZonedDate } from '@/lib/util/zonedTime';

interface CommentProps extends IntrinsicProps {
  commentId: string
  hasRated: boolean
  userAvatarUrl: string
  userDisplayName: string
  date: string
}

export default createComponent<CommentProps>('Comment', { style }, function Comment ({ className }, props) {
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const [ hasRated, setHasRated ] = useState(props.hasRated);
  const [ authState ] = useAuthStore();

  useEffect(() => {
    setHasRated(props.hasRated);
  }, [ props.commentId, props.hasRated ]);

  const rateComment = async (isHelpful: boolean) => {
    notify(<>Thanks for your feedback!</>);
    setHasRated(true);

    startLoading();
    try {
      await UsersModel.rateComment(props.commentId, isHelpful);
    } catch {
      notify('There was an error rating this comment. Please try again later.', 'error');
      setHasRated(false);
    }
    finishLoading();
  };

  return (
    <div className={className}>
      <Flex gap0 container>

        {/* User avatar */}
        <Flex>
          <UserAvatar
            small
            src={props.userAvatarUrl}
            displayName={props.userDisplayName}
          />
        </Flex>

        <Flex directionColumn fit>

          {/* Comment header */}
          <Flex alignCenter className='Comment__Header --marSm1__b'>

            {/* Title */}
            <h4>
              <Link href={`/member/profiles/${props.userDisplayName}`}>
                <strong>{props.userDisplayName}</strong>
              </Link>&nbsp;&mdash;&nbsp;
              {getZonedDate(props.date).toDateString()}
            </h4>

            {/* Comment rating UI */}
            {
              If(authState.ready && authState.user, () => (
                <Flex pullRight alignCenter>
                  {
                    If(isLoading, () => (
                      <Button primary small loading>&nbsp;</Button>
                    ))
                      .ElseIf(hasRated, () => (
                        <strong>Thanks for your feedback!</strong>
                      ))
                      .Else(() => (
                        <>
                          <Flex alignCenter className='--padSm2__r'>
                            <strong>Was this comment helpful?</strong>
                          </Flex>
                          <Buttons>
                            <Button primary small onClick={() => rateComment(true)}>
                              <IconFA icon={faThumbsUp} />
                            </Button>
                            <Button primary small onClick={() => rateComment(false)}>
                              <IconFA icon={faThumbsDown} />
                            </Button>
                          </Buttons>
                        </>
                      ))
                      .EndIf()
                  }
                </Flex>
              )).EndIf()
            }

          </Flex>

          {/* Comment body */}
          <Flex>
            {props.children}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
});
