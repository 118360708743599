import { createComponent, Flex, If, IntrinsicProps, Loader } from 'react-commons';

import style from './index.module.scss';

interface PodToolbarProps extends IntrinsicProps {
  isLoading?: boolean
  shouldShowLoader?: () => boolean // If true, will show the loader even if isLoading is false
  hideLoader?: boolean // If true, will hide the loader even if isLoading and showLoader is true
}

const classStates = [
  'isLoading'
];

export default createComponent<PodToolbarProps>('PodToolbar', { classStates, style }, function PodToolbar ({ className }, props) {
  const showLoader = props.hideLoader
    ? false
    : props.shouldShowLoader
      ? props.shouldShowLoader()
      : props.isLoading;

  return (
    <Flex gap0 container className={className}>
      {
        If(showLoader, () => (
          <Flex>
            <Loader />
          </Flex>
        )).EndIf()
      }
      {props.children}
    </Flex>
  );
});
