import { createComponent, IntrinsicProps, Slot } from 'react-commons';
import AccordionPod from '@/components/Pod/AccordionPod';
import GameDescription from '@/components/GameDescription';
import { FullGameData } from '@/lib/drupal/models/Games';

interface GameDescriptionPodProps extends IntrinsicProps, Pick<FullGameData, 'instructions' | 'requirements' | 'credits' | 'genres' | 'numPlays' | 'isDownloadGame' | 'description' | 'uid'> {
}

export default createComponent<GameDescriptionPodProps>('GameDescriptionPod', {}, function GameDescriptionPod ({}, props) {
  return (
    <AccordionPod>
      <Slot name='title'>
        Game Information
      </Slot>
      <GameDescription
        gameId={props.uid}
        description={props.description}
        instructions={props.instructions}
        requirements={props.requirements}
        credits={props.credits}
        genreIds={props.genres}
        numPlays={props.numPlays}
        isDownloadGame={props.isDownloadGame}
      />
    </AccordionPod>
  );
});
