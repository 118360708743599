import { createComponent, Flex, For, IntrinsicProps } from 'react-commons';

import style from './index.module.scss';
import Link from 'next/link';
import { useGenreBreadcrumbs } from '@/lib/hooks/useGameBreadcrumbs';

interface GenreBreadcrumbsProps extends IntrinsicProps {
  maxNumBreadcrumbs?: number
  genreIds: string[]
  isDownloadGame: boolean
}

export default createComponent<GenreBreadcrumbsProps>('GenreBreadcrumbs', { style }, function GenreBreadcrumbs ({ className }, props) {
  const maxNumBreadcrumbs = props.maxNumBreadcrumbs || 2;
  const breadcrumbs = useGenreBreadcrumbs(props.genreIds, props.isDownloadGame).slice(0, maxNumBreadcrumbs);

  return (
    <Flex tag='ul' className={className}>
      {
        For(breadcrumbs, (item, i) => (
          <Link key={i} href={item.href}>
            <li><a>{item.text}</a></li>
          </Link>
        ))
      }
    </Flex>
  );
});
