import Comment from '@/components/Comment';
import { CommentData } from '@/lib/drupal/models/Comments';
import { IntrinsicProps, createComponent, If, For, Button, Flex } from 'react-commons';
import { leaveCommentFormEvents } from '../forms/LeaveCommentForm';

import style from './index.module.scss';

interface CommentListProps extends IntrinsicProps {
  isLoading: boolean
  hasReachedEnd: boolean
  comments: CommentData[]
  fetchNextComments: () => void
}

export default createComponent<CommentListProps>('CommentList', { style }, function CommentList ({ className }, props) {
  return (
    <div className={className}>
      {
        If(props.comments.length, () => (
          <>
            {
              For(props.comments, (comment, index) => (
                <Comment
                  key={index}
                  commentId={comment.uid}
                  userAvatarUrl={comment.author.avatarUrl}
                  userDisplayName={comment.author.displayName}
                  date={comment.created}
                  className='Block'
                  hasRated={!!comment.userRating}
                >
                  {comment.body}
                </Comment>
              ))
            }
          </>
        ))
          .Else(() => (
            <div className='CommentList__Empty'>
              No comments yet for this game. <br />
              <a 
                className='RouterLink --marSm1 --inlineFlex' 
                onClick={() => leaveCommentFormEvents.trigger('showForm')}
              >
                Start the discussion!
              </a>
            </div>
          ))
          .EndIf()
      }
      {
        If(props.comments.length, () => (
          <Flex justifyCenter className='Block'>
            {
              If(props.hasReachedEnd, () => (
                <Button small disabled>
                  No more comments.
                </Button>
              ))
                .Else(() => (
                  <Button secondary small onClick={props.fetchNextComments} loading={props.isLoading}>
                    Show More
                  </Button>
                ))
                .EndIf()
            }
          </Flex>
        )).EndIf()
      }
    </div>
  );
});
