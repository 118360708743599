import React, { useEffect } from 'react';
import { createComponent, Container, Flex, Content, Title, ButtonLink, Buttons, Slot, RouterLink, For, If, Block, Button } from 'react-commons';
import PerDevice from '../PerDevice';
import onlineGenres from '@/lib/data/onlineGenres.json';

import style from './index.module.scss';
import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { classicOnlyRoutes, themeActions, useSwitchLayout, useThemeStore } from '@/lib/stores/theme';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';
import { useRouter } from 'next/router';

export default createComponent('AppFooter', { style }, function AppFooter ({ className }, props) {
  const genresToDisplay: (TaxonomyStub & { target?: '_blank' | '_self' | '_parent' | '_top' })[] = [
    onlineGenres.find(genre => genre.name === 'Action'),
    onlineGenres.find(genre => genre.name === 'Adventure'),
    onlineGenres.find(genre => genre.name === 'Card & Board'),
    {
      uid: '0',
      href: 'https://gameknot.com/',
      name: 'Chess',
      icon: '',
      target: '_blank'
    },
    onlineGenres.find(genre => genre.name === 'Jigsaw Puzzle'),
    onlineGenres.find(genre => genre.name === 'Family & Kids'),
    onlineGenres.find(genre => genre.name === 'Music & Photo'),
    onlineGenres.find(genre => genre.name === 'Puzzle'),
    onlineGenres.find(genre => genre.name === 'Racing'),
    onlineGenres.find(genre => genre.name === 'Shooting'),
    onlineGenres.find(genre => genre.name === 'Sports'),
    onlineGenres.find(genre => genre.name === 'Strategy'),
    onlineGenres.find(genre => genre.name === 'Word'),
  ];

  const [ authState ] = useAuthStore();
  useEffect(() => {
    if (!authState.ready) return;
    document.body.setAttribute(
      'data-premium', 
      (authState.user?.isPremiumUser || false).toString()
    );
  }, [ authState.ready, authState.user ]);

  const router = useRouter();
  const [ themeState, dispatch ] = useThemeStore();
  const switchLayout = useSwitchLayout();

  return (
    <footer className={className}>
      <Container>
        <PerDevice>

          {/* Mobile */}
          <Slot name='mobile'>
            <Block>
              <Title h5 size6>About Shockwave Games</Title>

              <p>Shockwave.com has games for everyone - free online games, free download games and new daily games each week, not to mention game reviews and ratings, a community full of gamers and plenty of exclusive member perks. Shockwave has a large selection of fun online and download dress up games. What are you waiting for? Game on!</p>
              
              <br />
              <p>Part of the Shockwave network.</p>

              <br />
              <p>
                Copyright © {new Date().getFullYear()} Shockwave LLC; all rights reserved.
                <br />
                Third-party trademarks are used solely for distributing the games indexed herein and no license or other affiliation is implied.
              </p>

              <br />
              <p>
                <a className='RouterLink' href='https://shockwavehelp.zendesk.com/hc/en-us/requests/new' target='_blank' rel='noreferrer'>Contact Us</a>
              </p>
            </Block>
          </Slot>

          {/* Desktop */}
          <Slot name='desktop'>
            <Content>
              <Title h3 size6>Free Online Games and More</Title>

              <p>
                Shockwave.com is the ultimate destination for free <RouterLink href='/'>online games</RouterLink>, free <RouterLink href='/download/all-games'>download games</RouterLink>, and more! This is your chance to play games 24-7, with a ton of great categories to choose from - {
                  For(genresToDisplay, (genre, index) => (
                    <React.Fragment key={index}>
                      &nbsp;<RouterLink href={genre.href} target={genre.target || '_self'}>{genre.name} Games</RouterLink>,
                    </React.Fragment>
                  ))
                } and more. Best of all, Shockwave.com is updated daily, making our library of games virtually endless!
              </p>
                
              <p>
                If you're a gamer looking for some serious fun, join&nbsp;
                <CaptureClickEvent
                  action='upsell'
                  location='footer'
                  href='/unlimited'
                  properties={() => ({
                    tag_name: 'upsell_footer',
                  })}
                >
                  <RouterLink href='/unlimited' className='upsell_footer'>Shockwave® UNLIMITED</RouterLink>
                </CaptureClickEvent> and receive access to exclusive online games and downloads for a low monthly fee. You'll enjoy unlimited play on all download games, no ad interruptions on all online games and brand new games each week. 
              </p>
                
              <p>
                Shockwave.com has games for everyone - free online games, free download games and new <RouterLink href='/online/daily-games'>daily games</RouterLink> each week, not to mention game reviews and ratings, a community full of gamers and plenty of exclusive member perks.
                Looking for more games? Shockwave has a large selection of fun online and download games. What are you waiting for? Game on! 
              </p>
              
              <p>
                Part of the Shockwave network.
              </p>

              <p>
                Copyright © {new Date().getFullYear()} Shockwave LLC; all rights reserved.
                <br />
                Third-party trademarks are used solely for distributing the games indexed herein and no license or other affiliation is implied.
              </p>

              <Buttons>
                <ButtonLink href='/about' secondary small>About Us</ButtonLink>
                <ButtonLink href='/help' secondary small>FAQ</ButtonLink>
                <ButtonLink href='/about/terms' secondary small>Terms of Use</ButtonLink>
                <ButtonLink href='/about/privacyPolicy' secondary small>Privacy Policy</ButtonLink>
                <a 
                  className='Button Button--secondary Button--small' 
                  href='https://shockwavehelp.zendesk.com/hc/en-us/requests/new' 
                  target='_blank'
                  rel='noreferrer'
                >
                  Contact Us
                </a>
                {
                  If(themeState.theme === 'default', () => (
                    <Button 
                      secondary 
                      small
                      onClick={() => dispatch(themeActions.setTheme('contrast'))}
                    >
                      High-Contrast Theme
                    </Button>
                  )).Else(() => (
                    <Button 
                      secondary 
                      small
                      onClick={() => dispatch(themeActions.setTheme('default'))}
                    >
                      Default Theme
                    </Button>
                  )).EndIf()
                }
                {
                  If(!classicOnlyRoutes.includes(router.pathname), () => (
                    <Button 
                      secondary 
                      small
                      onClick={() => switchLayout('modern')}
                    >
                      Use Modern Layout
                    </Button>
                  )).EndIf()
                }
              </Buttons>

            </Content>
          </Slot>
        
        </PerDevice>
      </Container>
    </footer>
  );
});
