import Link from 'next/link';
import Image from 'next/image';
import { Button, Flex, IconFA } from 'react-commons';
import { createComponent } from 'react-commons';

import style from './index.module.scss';
import { faAd, faCalendar, faCalendarWeek, faGamepad, faMobile, faMobileScreen, faStar } from '@fortawesome/free-solid-svg-icons';

export default createComponent('MobileUpsellPod', { style }, function MobileUpsellPod ({ className }) {
  return (
    <div className={`Box ${className}`}>
      <Flex gap0 container>
        <Flex 
          directionColumn
          justifyCenter
          className='MobileUpsellPod__Img'
        >
          <img
            src='/images/phoneGraphic.svg'
            alt='Shockwave Mobile App Graphic'
          />
        </Flex>
        <MobileUpsellPodText />
        <Flex justifyCenter alignCenter>
          <img
            className='MobileUpsellPod__Logo'
            src='/images/shockwaveMobile.svg'
            alt='Shockwave Mobile'
          />
        </Flex>
      </Flex>
    </div>
  );
});

export const MobileUpsellPodText = createComponent('MobileUpsellPodText', { style }, function MobileUpsellPodText ({ className }) {
  return (
    <Flex directionColumn justifyCenter className={className}>
      <p>
        <strong>
          Looking for Shockwave on the go?
        </strong>
      </p>
      <p>
        <IconFA icon={faMobileScreen} />
        A brand new Shockwave Mobile experience
      </p>
      <p>
        <IconFA icon={faCalendarWeek} />
        Daily Games and their archives in your pocket*
      </p>
      <p>
        <IconFA icon={faStar} />
        Rotating Daily Games including fan favorites!
      </p>
      <p className='--marSm2__t'>
        <small>
          *Archive access requires a Shockwave Unlimited subscription
        </small>
      </p>
    </Flex>
  );
});
