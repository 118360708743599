import { createComponent, Flex, If, IntrinsicProps } from 'react-commons';

interface GamePlayerTitle extends IntrinsicProps {
  isExclusive?: boolean
  useLightImage?: boolean
}

export default createComponent<GamePlayerTitle>('GamePlayerTitle', {}, function GamePlayerTitle ({}, props) {
  const imgSrc = props.useLightImage
    ? '/images/exclusiveBadge.svg'
    : '/images/exclusiveBadgeDark.svg';
  
  return (
    <Flex alignCenter>
      <div>{props.children}</div>
      {
        If(props.isExclusive, () => (
          <Flex alignCenter className='--padMd0__r'>&nbsp;<img 
            src={imgSrc}
            alt='Shockwave Unlimited Exclusive'
            width={90}
            height={26}
            style={{ width: '90px', height: '26px' }}
          /></Flex>
        )).EndIf()
      }
    </Flex>
  );
});
