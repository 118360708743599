import { createComponent, If, IntrinsicProps } from 'react-commons';
import StatsLabel from '@/components/StatsLabel';
import FavoriteButton from '@/components/FavoriteButton';
import RatingsPicker from '../RatingsPicker';
import ShareButton from '../ShareButton';
import ShareModal from '../ShareModal';

interface GamePlayerToolbarProps extends IntrinsicProps {
  id: string
  title: string
  rating: string
  numRatings?: number
  gameMediaUrl: string
}

export default createComponent<GamePlayerToolbarProps>('GamePlayerToolbar', {}, function GamePlayerToolbar ({}, props) {
  return (
    <>
      <ShareButton />
      <ShareModal 
        gameTitle={props.title}
        gameMediaUrl={props.gameMediaUrl}
      />
      <FavoriteButton 
        gameId={props.id} 
        gameTitle={props.title}
      />
      <RatingsPicker 
        gameId={props.id} 
        gameTitle={props.title} 
        rating={props.rating}
      />
      {
        If(props.numRatings, () => (<StatsLabel value={props.numRatings}>ratings</StatsLabel>))
          .Else(() => (<div className='StatsLabel'>No Ratings</div>))
          .EndIf()
      }
    </>
  );
});
