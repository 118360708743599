import { Block, createComponent, If, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import DailyGame from '@/components/DailyGame';
import GamePlayerToolbar from '@/components/GamePlayerToolbar';
import { FullGameData } from '@/lib/drupal/models/Games';
import GamePlayerTitle from '@/components/GamePlayerTitle';
import GenreBreadcrumbs from '@/components/GenreBreadcrumbs';

interface DailyGamePodProps extends IntrinsicProps, FullGameData {
  h1?: boolean
  slug: string
  isPremiumUser: boolean
  isChallengeJigsaw: boolean
}

export default createComponent<DailyGamePodProps>('DailyGamePod', {}, function DailyGamePod ({}, props) {

  return (
    <Pod h1={props.h1}>
      <Slot name='title'>
        <GamePlayerTitle 
          isExclusive={props.isSwuExclusive}
        >
          {props.title}
        </GamePlayerTitle>
      </Slot>
      <Slot name='subtitle'>
        {
          If(props.genres.length, () => (
            <GenreBreadcrumbs
              genreIds={props.genres}
              isDownloadGame={props.isDownloadGame}
            />
          )).EndIf()
        }
      </Slot>
      <Slot name='toolbar'>
        <GamePlayerToolbar
          id={props.uid}
          title={props.title}
          rating={props.rating}
          numRatings={props.numRatings}
          gameMediaUrl={props.thumbnailImg ?? props.thumbnailImgXl ?? props.mobileThumbnail}
        />
      </Slot>
      <Block>
        <DailyGame
          id={props.uid}
          title={props.title}
          description={props.textDescription} 
          href={props.href} 
          imgSrc={props.thumbnailImgXl}
          billboardSrc={props.billboardImg}
          isPremiumUser={props.isPremiumUser}
          slug={props.slug}
          isJigsawGame={props.pageLayout === 'jigsaw'}
          isChallengeJigsaw={props.isChallengeJigsaw}
        />
      </Block>
    </Pod>
  );
});
