import { Button, createComponent, IconFA, IntrinsicProps, useModal } from 'react-commons';
import { faShare } from '@fortawesome/free-solid-svg-icons';

interface ShareButtonProps extends IntrinsicProps {
  
}

export default createComponent<ShareButtonProps>('ShareButton', {}, function ShareButton ({}, props) {
  const { openModal } = useModal();

  return (
    <Button 
      small 
      primary 
      onClick={() => openModal('shareGame')}
    >
      <IconFA icon={faShare} />
    </Button>
  );
});
