import { createComponent, Flex, If, IntrinsicProps, Loader, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import CommentListToolbar from '@/components/CommentListToolbar';
import { useCommentList } from '@/lib/hooks/useCommentList';
import CommentList from '@/components/CommentList';
import { useState } from 'react';

interface DiscussionPodProps extends IntrinsicProps {
  gameId: string
}

export default createComponent<DiscussionPodProps>('DiscussionPod', {}, function DiscussionPod ({ className }, props) {
  const {
    isLoading,
    sortBy,
    setSortBy,
    hasReachedEnd,
    comments,
    fetchInitialComments,
    fetchNextComments,
  } = useCommentList(props.gameId);

  const [ isReady, setReady ] = useState(false);

  return (
    <Pod
      lazyLoad='whenInViewport'
      onBeforeLazyLoad={async (finish) => {
        await fetchInitialComments();
        setReady(true);
        finish();
      }}
    >
      <Slot name='title'>Discussion</Slot>
      <Slot name='toolbar'>
        {
          If(comments.length, () => (
            <CommentListToolbar 
              isLoading={isLoading} 
              sortBy={sortBy} 
              setSortBy={setSortBy}          
            />
          )).EndIf()
        }
      </Slot>
      <>
        {
          If(isReady, () => (
            <CommentList 
              comments={comments} 
              isLoading={isLoading} 
              hasReachedEnd={hasReachedEnd} 
              fetchNextComments={fetchNextComments} 
            />
          ))
            .Else(() => (
              <Flex alignCenter justifyCenter className='--padMd2'>
                <Loader large />
              </Flex>
            ))
            .EndIf()
        }
      </>
    </Pod>
  );
});
