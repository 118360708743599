import { ASPECT_4_3, Button, createComponent, If, IntrinsicProps, Slot } from 'react-commons';
import GamePlayer, { GamePlayerProps } from '@/components/GamePlayer';
import Image from 'next/image';

import style from './index.module.scss';
import { useRouter } from 'next/router';
import { getDailyGameSrc } from '@/lib/drupal/models/Games';
import { displayPreroll } from '@/lib/util/displayPreroll';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { getFieldsFromDateQuery } from '@/lib/util/gamePlayerTools';
import { usePlaywirePrecontent } from '@/components/playwire/Playwire';

interface SwGamePlayerProps extends IntrinsicProps {
  src: string
  previewImg: string
  autoPlay?: boolean | 'afterInterstitial'
  width?: number
  height?: number
  isFlash: boolean
  fullScreen?: boolean
  onClickExitFullScreen?: () => void
}

export default createComponent<SwGamePlayerProps>('SwGamePlayer',  { style }, function SwGamePlayer ({ className }, props) {
  const [ authState ] = useAuthStore();
  const router = useRouter();

  const [ , fields ] = getFieldsFromDateQuery(router.query.date as string);
  const src = getDailyGameSrc(props.src, fields);

  const showPrecontent = usePlaywirePrecontent();

  const gamePlayerProps: GamePlayerProps = {
    fluid: false,
    spacer: undefined,
    width: undefined,
    height: undefined,
    previewImage: props.previewImg,
    gameSrc: src,
    className: className + (props.autoPlay ? ' SwGamePlayer--autoPlay' : ''),
    autoPlay: props.autoPlay,
    embedTag: 'iframe',
    onClickPlay: async (showGame) => {
      if (authState.user?.isPremiumUser) {
        return showGame();
      }
      showPrecontent(showGame);
    },
    onClickExitFullScreen: props.onClickExitFullScreen,
    supportsMobile: {
      landscape: true,
      portrait: true,
    },
    isFlash: props.isFlash,
    fullScreen: props.fullScreen,
  };

  if (props.width) {
    gamePlayerProps.width = props.width;
    gamePlayerProps.height = props.height;
  } else {
    gamePlayerProps.fluid = true;
    gamePlayerProps.spacer = ASPECT_4_3;
  }
  
  return (
    <GamePlayer   
      {...gamePlayerProps}
    >
      <Slot name='playButton'>
        {
          If(!props.autoPlay, () => (
            <Button large primary className='SwGamePlayer__PlayButton'>
              <Image
                src='/images/playButton.svg'
                alt='Play'
                width={64}
                height={88}
              />&nbsp;PLAY
            </Button>
          )).EndIf()
        }
      </Slot>
      <Slot name='interstitial'>
        <div id='playwire_precontent_ad' />
      </Slot>
    </GamePlayer>
  );
});
