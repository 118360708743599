import { useRef } from 'react';
import { Block, createComponent, If, IntrinsicProps, useResizeX } from 'react-commons';
import { App, Layout } from 'react-commons';

import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import { CaptureClickEventContext } from '@/lib/util/captureClickEvent';

import style from './index.module.scss';
import PlaywireAside, { PlaywireAside2 } from '@/components/playwire/PlaywireAside';
import { useAuthStore } from '@/lib/drupal/stores/auth';

interface ClassicGamePageLayoutProps extends IntrinsicProps {
  isFullscreen?: boolean
  hideLeaderboardAd?: boolean
  hideHeader?: boolean
  pageName?: string
  pageEventSettings?: any
}

const classStates = [
  'isFullscreen',
];

export default createComponent<ClassicGamePageLayoutProps>('ClassicGamePageLayout', { style, classStates }, function ClassicGamePageLayout ({ className }, props) {
  const contentElRef = useRef<HTMLDivElement>(null);
  const [ authState ] = useAuthStore();

  useResizeX((screenWidth) => {
    const el = contentElRef.current;

    const adColumnWidth = 418 + 16; // 418px ad + 16px margin
    const gamePlayerPodWidth = el.querySelector('.ClassicGamePlayerBlock > div').getBoundingClientRect().width;
    const containerPadding = 32; // 16px padding on each side

    const narrowBreakpoint = gamePlayerPodWidth + adColumnWidth + containerPadding;
    const wideBreakpoint = 1714;

    if (screenWidth >= wideBreakpoint) {
      el.classList.remove('--isNarrow');
      el.classList.add('--isWide');
    }
    else if (screenWidth >= narrowBreakpoint) {
      el.classList.remove('--isWide');
      el.classList.add('--isNarrow');
    }
    else {
      el.classList.remove('--isWide');
      el.classList.remove('--isNarrow');
    }
  });

  return (
    <CaptureClickEventContext.Provider value={{
      page: props.pageName,
      settings: props.pageEventSettings || {},
    }}>
      <Layout className={className}>
        {
          If(!props.hideHeader, () => (
            <App.Header>
              <AppHeader />
            </App.Header>
          )).EndIf()
        }

        <App.Main>
          <div
            ref={contentElRef} 
            className='Flex Flex--justifyCenter ClassicGamePageLayout__Content --isWide'
          >
            {
              If(!props.isFullscreen, () => (
                <aside>
                  {
                    If(!authState.user?.isPremiumUser, () => (
                      <PlaywireAside2 />
                    )).EndIf()
                  }
                </aside>
              )).EndIf()
            }

            <div>
              {props.children}
            </div>

            {
              If(!props.isFullscreen, () => (
                <aside>
                  {
                    If(!authState.user?.isPremiumUser, () => (
                      <PlaywireAside />
                    )).EndIf()
                  }
                </aside>
              )).EndIf()
            }
          </div>
        </App.Main>

        <App.Footer>
          <AppFooter />
        </App.Footer>
      </Layout>
    </CaptureClickEventContext.Provider>
  );
});

export const ClassicGamePageInner = createComponent('ClassicGamePageInner', { style }, function ClassicGamePageInner ({ className }, props) {
  return (
    <div className={className}>
      {props.children}
    </div>
  );
});

export const ClassicGamePlayerBlock = createComponent('ClassicGamePlayerBlock', { style }, function ClassicGamePlayerBlock ({ className }, props) {
  return (
    <Block className={className}>
      {props.children}
    </Block>
  );
});