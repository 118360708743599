import { createComponent, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import LeaderboardTable from '@/components/LeaderboardTable';
import LeaderboardToolbar from '@/components/LeaderboardToolbar';
import { useLeaderboards } from '@/lib/hooks/useLeaderboards';

interface LeaderboardPodProps extends IntrinsicProps {
  keyword: string
}

export default createComponent<LeaderboardPodProps>('LeaderboardPod', {}, function LeaderboardPod ({ className }, props) {
  const {
    isLoading,
    error,
    toolbarState,
    tableData,
    updateToolbarState,
    retry,
  } = useLeaderboards(props.keyword);
  
  return (
    <Pod className={className}>
      <Slot name='title'>High Scores</Slot>
      <Slot name='toolbar'>
        <LeaderboardToolbar
          isLoading={isLoading}
          error={error}
          toolbarState={toolbarState}
          updateToolbarState={updateToolbarState}
        />
      </Slot>
      <LeaderboardTable
        isLoading={isLoading}
        error={error}
        tableData={tableData}
        retry={retry}
      />
    </Pod>
  );
});
