import { ButtonLink, createComponent, Flex, IntrinsicProps, Slot } from 'react-commons';
import Pod from '@/components/Pod';
import GamePlayerTitle from '@/components/GamePlayerTitle';
import { format } from 'date-fns';
import GamepadIcon from '@/components/GamepadIcon';
import style from './index.module.scss';
import Link from 'next/link';
import { getZonedDate } from '@/lib/util/zonedTime';

interface DailyBonusPodProps extends IntrinsicProps {
  gameName: string
  bonusGameImgSrc0: string
  bonusGameUrl0: string
  bonusGameImgSrc1: string
  bonusGameUrl1: string
  weeklyStartDate?: string
}

export default createComponent<DailyBonusPodProps>('DailyBonusPod', { style }, function DailyBonusPod ({ className }, props) {
  const todaysDate = getZonedDate();

  const todayStr = (() => {
    if (props.weeklyStartDate) {
      const date = getZonedDate(props.weeklyStartDate);
      const dayOfWeek = format(date, 'EEEE');

      return `New every ${dayOfWeek}!`;
    } else {
      return 'New every day!';
    }
  })();
  
  const tomorrowStr = (() => {
    const date = getZonedDate(todaysDate.getTime());
    date.setDate(todaysDate.getDate() + 1);

    return format(getZonedDate(date), 'MMMM d');
  })();


  return (
    <Pod className={className}>
      <Slot name='title'>
        <GamePlayerTitle 
          isExclusive={true}
          useLightImage={true}
        >
          Shockwave Unlimited Exclusives
        </GamePlayerTitle>
      </Slot>
      <Flex justifySpaceAround gap0 container>
        <Link href={props.bonusGameUrl0}>
          <div className='DailyBonusPod__Game'>
            <Flex alignCenter>
              <div>
                <img
                  src={props.bonusGameImgSrc0}
                  alt={props.gameName + ' Game Thumbnail'}
                  width={120}
                  height={90}
                  className='DailyBonusPod__Thumbnail'
                />
              </div>
              <div>
                <h6>Today's Bonus Puzzle</h6>
                <p>{todayStr}</p>
                <ButtonLink href={props.bonusGameUrl0} secondary small>
                  Play Now <GamepadIcon />
                </ButtonLink>
              </div>
            </Flex>
          </div>
        </Link>
        <Link href={props.bonusGameUrl1}>
          <div className='DailyBonusPod__Game'>
            <Flex alignCenter>
              <div>
                <img
                  src={props.bonusGameImgSrc1}
                  alt={props.gameName + ' Game Thumbnail'}
                  width={120}
                  height={90}
                  className='DailyBonusPod__Thumbnail'
                />
              </div>
              <div>
                <h6>Tomorrow's Puzzle Today</h6>
                <p>{tomorrowStr}</p>
                <ButtonLink href={props.bonusGameUrl1} secondary small>
                  Play Now <GamepadIcon />
                </ButtonLink>
              </div>
            </Flex>
          </div>
        </Link>
      </Flex>
    </Pod>
  );
});
