import Link from 'next/link';
import Image from 'next/image';
import { Button, Flex, IconFA } from 'react-commons';
import { createComponent } from 'react-commons';

import style from './index.module.scss';
import { faAd, faCalendar, faCalendarWeek, faGamepad } from '@fortawesome/free-solid-svg-icons';

export default createComponent('UpsellPod', { style }, function UpsellPod ({ className }) {
  return (
    <Link href='/unlimited'>
      <a className={`Box ${className}`}>
        <Flex gap0>
          <Flex directionColumn className='UpsellPod__Img'>
            <Image
              src='/images/shockwaveUnlimited.svg'
              alt='Shockwave Unlimited'
              width={218}
              height={76}
            />
            <p className='--marSm2__t'><small>*based on a one year subscription</small></p>
          </Flex>
          <UpsellPodText />
        </Flex>
        <Button secondary medium>Subscribe Now</Button>
      </a>
    </Link>
  );
});

export const UpsellPodText = createComponent('UpsellPodText', { style }, function UpsellPodText ({ className }) {
  return (
    <Flex directionColumn justifyCenter className={className}>
      <p>
        <strong>
          Play all you want for only $4.95 / month*
        </strong>
      </p>
      <p>
        <IconFA icon={faGamepad} />
        Over 1,300+ Premium PC Games
      </p>
      <p>
        <IconFA icon={faCalendarWeek} />
        Access Daily Game Archives
      </p>
      <p>
        <IconFA icon={faAd} />
        No Ad Interruptions
      </p>
    </Flex>
  );
});
