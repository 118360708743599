import { useEffect, useRef } from 'react';
import { Block, Flex, If, IntrinsicProps, Title, toClassName } from 'react-commons';
import { createComponent } from 'react-commons';
import { useInViewport } from 'react-in-viewport';

import style from './index.module.scss';

export interface PodProps extends IntrinsicProps {
  gutter?: 'none' | 'full'
  lazyLoad?: 'immediately' | 'whenInViewport'
  centerFooter?: boolean
  slimTitle?: boolean
  onBeforeLazyLoad?: (finish: () => void) => void
  onAfterLazyLoad?: () => void
  h1?: boolean
}

export default createComponent<PodProps>('Pod', { style }, function Pod ({ slots, mergeClassNames, style }, props) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const hasLoadedRef = useRef(!props.lazyLoad);

  const { 
    inViewport 
  } = useInViewport(elRef, {}, { disconnectOnLeave: true }, props);

  {
    const { 
      lazyLoad, 
      onBeforeLazyLoad, 
      onAfterLazyLoad 
    } = props;

    useEffect(() => {
      if (!lazyLoad) return;
      if (hasLoadedRef.current) return;
      if (lazyLoad === 'whenInViewport' && !inViewport) return;

      hasLoadedRef.current = true;

      const load = async () => {
        if (onBeforeLazyLoad) {
          await new Promise<void>((resolve) => {
            onBeforeLazyLoad(resolve);
          });
        }
        onAfterLazyLoad?.();
      };

      if (
        (lazyLoad === 'immediately') ||
        (lazyLoad === 'whenInViewport' && inViewport)
      ) {
        load();
      }
    }, [ inViewport, lazyLoad, onAfterLazyLoad, onBeforeLazyLoad ]);
  }

  const className = mergeClassNames(
    'Box',
    toClassName('Pod', { 
      [ `gutter-${props.gutter || 'full'}` ]: true,
      [ 'slimTitle' ]: props.slimTitle,
    })
  );

  return (
    <div className={className} ref={elRef} onClick={props.onClick} style={style}>
      {
        If(slots.title && !slots.toolbar, () => (
          <Title
            h1={props.h1}
            h2={!props.h1}
            size6
            className='Pod__Title'
          >
            {slots.title}
          </Title>
        ))
          .ElseIf(slots.toolbar, () => (
            <Flex alignStart>
              {
                If(slots.title, () => (
                  <Title size6 className='Pod__Title'>{slots.title}</Title>
                ))
                  .EndIf()
              }
              <Flex pullRight className='Pod__Toolbar'>
                {slots.toolbar}
              </Flex>
            </Flex>
          ))
          .EndIf()
      }
      {
        If(slots.subtitle, () => (
          <Block className='Pod__Subtitle'>{slots.subtitle}</Block>
        )).EndIf()
      }
      <Block className='Pod__Content'>
        {slots.defaultSlot}
      </Block>
      {
        If(slots.footer, () => (
          <Flex className='Pod__Footer' wide={props.centerFooter}>
            <Flex pullRight={!props.centerFooter} fit={props.centerFooter} justifyCenter={props.centerFooter}>
              {slots.footer}
            </Flex>
          </Flex>
        ))
          .EndIf()
      }
    </div>
  );
});
